import React from 'react';
import { Message, MessageDirection } from 'src/api/generated';
import AttachmentList from '../../attachments/attachment-list';

interface MessageItemProps {
  message: Message;
}

export const MessageItem: React.FC<MessageItemProps> = ({ message }) => {
  const isOutbound = message.direction === MessageDirection.OUTBOUND;
  const containerClasses = `flex ${isOutbound ? 'justify-end' : 'justify-start'}`;
  const messageClasses = `max-w-md p-2 rounded-lg shadow-md break-words ${
    isOutbound ? 'bg-blue-500 text-white' : 'bg-gray-200'
  }`;
  const dateClasses = `text-xs mt-1 text-right ${
    isOutbound ? 'text-gray-300' : 'text-gray-500'
  }`;

  return (
    <div className={containerClasses}>
      <div className={messageClasses}>
        {message.attachments && (
          <div className="mb-2">
            <AttachmentList attachments={message.attachments} />
          </div>
        )}
        {message.content && <p className="text-sm">{message.content}</p>}
        <p className={dateClasses}>
          {new Date(message.createdAt).toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit',
          })}
        </p>
      </div>
    </div>
  );
};

export default MessageItem;
