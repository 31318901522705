import React, { useState } from 'react';
import MediaModal from '../media-modal';

interface VideoMediaProps {
  url: string;
}

const VideoMedia: React.FC<VideoMediaProps> = ({ url }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <video
        className="w-full h-auto rounded-lg cursor-pointer max-w-[30rem] max-h-[30rem]"
        onClick={() => setIsModalOpen(true)}
        muted
      >
        <source src={url} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <MediaModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        type="video"
        url={url}
      />
    </>
  );
};

export default VideoMedia;
