import React from 'react';
import {
  PLACEHOLDER_PREFIX,
  PLACEHOLDER_SUFFIX,
} from 'src/modules/messaging/whatsapp/util/template.util';
import LabelTextInput from 'src/modules/shared/components/atoms/inputs/label-text-input';

interface PlaceholderInputsProps {
  placeholderValues: string[];
  onPlaceholderChange: (values: string[]) => void;
}

const PlaceholderInputs: React.FC<PlaceholderInputsProps> = ({
  placeholderValues,
  onPlaceholderChange,
}) => {
  const handleChange = (index: number, value: string) => {
    const newValues = [...placeholderValues];
    newValues[index] = value;
    onPlaceholderChange(newValues);
  };

  const labelText = (index: number) => {
    // TODO: check when implementing i18n
    return `Ingresa un valor para ${PLACEHOLDER_PREFIX}${index}${PLACEHOLDER_SUFFIX}`;
  };

  return (
    <div className="space-y-4">
      {placeholderValues.map((_placeholder, index) => (
        <LabelTextInput
          key={index}
          // TODO: check when implementing i18n
          label={labelText(index + 1)}
          value={placeholderValues[index] || ''}
          onChange={(e) => handleChange(index, e.target.value)}
          variant="primary"
          size="sm"
        />
      ))}
    </div>
  );
};

export default PlaceholderInputs;
