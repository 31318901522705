import React from 'react';

interface AudioMediaProps {
  url: string;
  type?: string;
}

export const AudioMedia: React.FC<AudioMediaProps> = ({
  url,
  type = 'audio/mpeg',
}) => (
  <audio controls className="w-full">
    <source src={url} type={type} />
    Your browser does not support the audio element.
  </audio>
);

export default AudioMedia;
