import React from 'react';
import Modal from 'src/modules/shared/components/molecules/modals/modal';

interface MediaModalProps {
  isOpen: boolean;
  onClose: () => void;
  type: 'image' | 'video';
  url: string;
  alt?: string;
}

const MediaModal: React.FC<MediaModalProps> = ({
  isOpen,
  onClose,
  type,
  url,
  alt,
}) => (
  <Modal
    isOpen={isOpen}
    onClose={onClose}
    dialogClassName="!max-w-full !max-h-full"
    body={
      <div className="flex w-full justify-center items-center">
        {type === 'image' ? (
          <img
            src={url}
            alt={alt}
            className="max-w-full max-h-full cursor-pointer"
            onClick={onClose}
          />
        ) : (
          <video controls className="max-w-full max-h-full">
            <source src={url} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )}
      </div>
    }
  />
);

export default MediaModal;
