import { useState, useEffect, useCallback } from 'react';
import { WhatsAppTemplate } from 'src/api/generated';
import { buildTemplateMessageText } from '../util/template.util';

export const useTemplateMessage = (templates: WhatsAppTemplate[]) => {
  const [selectedTemplate, setSelectedTemplate] = useState<WhatsAppTemplate>();
  const [messageContent, setMessageContent] = useState('');

  useEffect(() => {
    if (!selectedTemplate) {
      setMessageContent('');
      return;
    }

    const templateText = buildTemplateMessageText(selectedTemplate);
    setMessageContent(templateText);
  }, [selectedTemplate]);

  const selectTemplate = useCallback(
    (templateId?: string) => {
      const template = templates.find((t) => t.id === templateId);
      setSelectedTemplate(template);
    },
    [templates]
  );

  return { selectedTemplate, messageContent, selectTemplate };
};

export const usePlaceholderValues = (messageContent: string) => {
  const [placeholderValues, setPlaceholderValues] = useState<string[]>([]);

  useEffect(() => {
    const matches = messageContent.match(/{{\d+}}/g) || [];
    const uniquePlaceholders = Array.from(new Set(matches));
    setPlaceholderValues(uniquePlaceholders.map(() => ''));
  }, [messageContent]);

  return { placeholderValues, setPlaceholderValues };
};
