import {
  BodyComponentType,
  FooterComponentType,
  HeaderComponentType,
  WhatsAppTemplate,
  WhatsAppTemplateBodyComponent,
  WhatsAppTemplateFooterComponent,
  WhatsAppTemplateHeaderComponent,
} from 'src/api/generated';

// Note: duplicated in shared library
// TODO: check how to install shared library in frontend

export const ESCAPED_PLACEHOLDER_PREFIX = '\\{\\{';
export const ESCAPED_PLACEHOLDER_SUFFIX = '\\}\\}';
export const PLACEHOLDER_PREFIX = '{{';
export const PLACEHOLDER_SUFFIX = '}}';

export const buildTemplateMessageText = (
  template: WhatsAppTemplate
): string => {
  const headerComponent = template.components.find(
    (comp) =>
      comp.type.toLowerCase() === HeaderComponentType.HEADER.toLowerCase()
  ) as WhatsAppTemplateHeaderComponent;
  const bodyComponent = template.components.find(
    (comp) => comp.type.toLowerCase() === BodyComponentType.BODY.toLowerCase()
  ) as WhatsAppTemplateBodyComponent;
  const footerComponent = template.components.find(
    (comp) =>
      comp.type.toLowerCase() === FooterComponentType.FOOTER.toLowerCase()
  ) as WhatsAppTemplateFooterComponent;

  const headerText = headerComponent?.text || '';
  const bodyText = bodyComponent?.text || '';
  const footerText = footerComponent?.text || '';

  return [headerText, bodyText, footerText].filter(Boolean).join('\n');
};

export const replacePlaceholders = (
  templateText: string,
  placeholderValues: string[],
  replacer: string = ''
): string => {
  return placeholderValues.reduce((text, value, index) => {
    if (!value) return text;

    const regex = new RegExp(
      `${ESCAPED_PLACEHOLDER_PREFIX}${index + 1}${ESCAPED_PLACEHOLDER_SUFFIX}`,
      'g'
    );
    return text.replace(regex, `${replacer}${value}${replacer}`);
  }, templateText);
};

export const splitMessageByLines = (message: string): string[] => {
  return message.split('\n');
};

// TODO: check when implementing i18n
export const translateTemplateValues = (key: string, value: string): string => {
  const translations: Record<string, Record<string, string>> = {
    language: {
      en_US: 'Inglés (EE.UU.)',
      es_ES: 'Español (España)',
      fr_FR: 'Francés (Francia)',
      de_DE: 'Alemán (Alemania)',
      // Add more language translations as needed
    },
    status: {
      APPROVED: 'Aprobado',
      IN_APPEAL: 'En apelación',
      PENDING: 'Pendiente',
      REJECTED: 'Rechazado',
      PENDING_DELETION: 'Pendiente de eliminación',
      DELETED: 'Eliminado',
      DISABLED: 'Deshabilitado',
      PAUSED: 'Pausado',
      LIMIT_EXCEEDED: 'Límite excedido',
      ARCHIVED: 'Archivado',
    },
    category: {
      ACCOUNT_UPDATE: 'Actualización de cuenta',
      PAYMENT_UPDATE: 'Actualización de pago',
      PERSONAL_FINANCE_UPDATE: 'Actualización de finanzas personales',
      SHIPPING_UPDATE: 'Actualización de envío',
      RESERVATION_UPDATE: 'Actualización de reserva',
      ISSUE_RESOLUTION: 'Resolución de problemas',
      APPOINTMENT_UPDATE: 'Actualización de cita',
      TRANSPORTATION_UPDATE: 'Actualización de transporte',
      TICKET_UPDATE: 'Actualización de ticket',
      ALERT_UPDATE: 'Actualización de alerta',
      AUTO_REPLY: 'Respuesta automática',
      TRANSACTIONAL: 'Transaccional',
      OTP: 'Contraseña de un solo uso',
      UTILITY: 'Utilidad',
      MARKETING: 'Marketing',
      AUTHENTICATION: 'Autenticación',
    },
  };

  return translations[key]?.[value] || value;
};
