import React, { useState } from 'react';
import MediaModal from '../media-modal';

interface ImageMediaProps {
  url: string;
  alt?: string;
}

const ImageMedia: React.FC<ImageMediaProps> = ({ url, alt = 'image' }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <img
        src={url}
        alt={alt}
        className="w-full h-auto rounded-lg cursor-pointer max-w-[30rem] max-h-[30rem]"
        onClick={() => setIsModalOpen(true)}
      />
      <MediaModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        type="image"
        url={url}
        alt={alt}
      />
    </>
  );
};

export default ImageMedia;
